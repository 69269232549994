@media all and (min-width: 480px) {
	.Deals {
		padding: 60px 0;
		max-width: 480px;
		margin: 0 auto;
	}

	.Deals form {
		margin: 0 auto;
		max-width: 480px;
	}
	.flight-card-link {
		text-align: left;
	}
}

.page-header,
.page-description {
	text-align: center;
}

.Deals .lander {
	padding: 0px 0;
	text-align: center;
}

.list-group {
	padding-bottom: 100px;
}

.Deals .lander div {
	margin: 0 auto;
	max-width: 480px;
}

.Deals .lander h1 {
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
}

.sectionSubtitle {
	font-style: italic;
	font-size: 12px;
	text-align: center;
}

.slideContainer {
	margin: auto;
	max-width: 480px;
	background: #eeeeee;
}

.Deals .lander p {
	color: #999;
}

.Deals .lander div {
	padding-top: 0px;
}
.Deals .lander div a:first-child {
	margin-right: 20px;
}

.slick-dots {
	top: -5px;
}

.slideHeader {
	text-align: center;
}

.special-text:not(.expanded)::after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.special-text {
	position: relative;
	max-height: 80px;
	overflow: hidden;
	transition: max-height 0.4s ease;
}

.special-text.expanded {
	max-height: 1000px;
	transition: max-height 0.4s ease-in-out;
}

.pageDescriptionExpandable-text:not(.expanded)::after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.pageDescriptionExpandable-text {
	position: relative;
	max-height: 0px;
	overflow: hidden;
	transition: max-height 0.4s ease;
}

.pageDescriptionExpandable-text.expanded {
	max-height: 1000px;
	transition: max-height 0.4s ease-in-out;
}

.expandPageDescription {
	font-size: 15px;
	text-align: center;
}

.carouselContentDiv {
	padding-top: 30px;
}

.slideCountDiv {
	padding-top: 10px;
}

.slideContainer {
	top: 40px;
}

.slick-arrow {
	top: 0%;
	color: #000000;
	background: #bbbbbb;
	width: 40px;
	height: 40px;
	top: -20px;
	position: top;
}

.slick-next {
	right: 0px;
}

.slick-prev {
	left: 0px;
}

details {
	margin-bottom: 20px;
}

.LoaderButton .spinning.glyphicon {
	margin-right: 7px;
	top: 2px;
	animation: spin 1s infinite linear;
}
@keyframes spin {
	from {
		transform: scale(1) rotate(0deg);
	}
	to {
		transform: scale(1) rotate(360deg);
	}
}

.expand-button {
	border-width: 3px;
	background-color: #ddd;
}

@media all and (min-width: 480px) {
	.Login {
		padding: 0px 0;
		max-width: 480px;
		margin: 0 auto;
	}

	.Login form {
		margin: 0 auto;
	}

	.Login form a {
		margin-bottom: 15px;
		display: block;
		font-size: 14px;
		color: blue;
	}
}

.Home .lander {
	padding: 0px 0;
	text-align: center;
}
.linkBtn {
	margin-top: 5px;
	margin-bottom: 5px;
}

.Home .lander h1 {
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
}

.Home .lander p {
	color: #999;
}

.Home .lander div {
	padding-top: 0px;
}
.Home .lander div a:first-child {
	margin-right: 20px;
}

.Home .notes h4 {
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
	overflow: hidden;
	line-height: 1.5;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.Home .notes p {
	color: #666;
}
a {
	color: black;
	text-decoration: none; /* no underline */
}
a:hover {
	color: black;
	text-decoration: none; /* no underline */
}

.Notes form {
	padding-bottom: 15px;
}

.Notes form textarea {
	height: 300px;
	font-size: 24px;
}

@media all and (min-width: 480px) {
	.Signup {
		padding: 60px 0;
	}

	.Signup form {
		margin: 0 auto;
		max-width: 320px;
	}
}

.Signup form div div label a {
	color: blue;
	font-weight: bold;
}

.sectionSubtitle {
	font-style: italic;
	font-size: 12px;
}

.Signup .pageButtonGroup div {
	margin: 0 auto;
	max-width: 320px;
	text-align: center;
}
/* #root > div > div > div > div > button */
.Signup .pageButtonGroup div button:first-child {
	margin-right: 20px;
}

.Signup form span.help-block {
	font-size: 14px;
	padding-bottom: 10px;
	color: #999;
}

FormGroup.checkbox {
	display: inline-block;
	display: flex;
	flex-direction: row-reverse;
}

FormGroup.checkbox > label {
	display: flex;
	flex-direction: row-reverse;
}

@media all and (min-width: 480px) {
	.ResetPassword {
		padding: 60px 0;
	}

	.ResetPassword form {
		margin: 0 auto;
		max-width: 320px;
	}

	.ResetPassword .success {
		max-width: 400px;
	}
}

.ResetPassword .success {
	margin: 0 auto;
	text-align: center;
}
.ResetPassword .success .glyphicon {
	color: grey;
	font-size: 30px;
	margin-bottom: 30px;
}

.NewNote form {
	padding-bottom: 15px;
}

.NewNote form textarea {
	height: 300px;
	font-size: 24px;
}

@media all and (min-width: 480px) {
	.Settings {
		padding: 60px 0;
		margin: 0 auto;
		max-width: 480px;
	}
	.linkBtn {
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.btcpay-form { display: inline-flex; align-items: center; justify-content: center; } 
	.btcpay-form--inline { flex-direction: row; } 
	.btcpay-form--block { flex-direction: column; } 
	.btcpay-form--inline .submit { margin-left: 15px; } 
	.btcpay-form--block select { margin-bottom: 10px; } 
	.btcpay-form .btcpay-custom-container{ text-align: center; }
	.btcpay-custom { display: flex; align-items: center; justify-content: center; } 
	.btcpay-form .plus-minus { cursor:pointer; font-size:25px; line-height: 25px; background: #DFE0E1; height: 30px; width: 45px; border:none; border-radius: 60px; margin: auto 5px; display: inline-flex; justify-content: center; } 
	.btcpay-form select { -webkit-appearance: none; appearance: none; color: currentColor; background: transparent; border:1px solid transparent; display: block; padding: 1px; margin-left: auto; margin-right: auto; font-size: 11px; cursor: pointer; } 
	.btcpay-form select:hover { border-color: #ccc; } 
	#btcpay-input-price { -moz-appearance: none; -webkit-appearance: none; border: none; box-shadow: none; text-align: center; font-size: 25px; margin: auto; border-radius: 5px; line-height: 35px; background: #fff; } 
	#btcpay-input-price::-webkit-outer-spin-button, #btcpay-input-price::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }

}

@media all and (min-width: 480px) {
	.Settings {
		padding: 60px 0;
		margin: 0 auto;
		max-width: 480px;
	}
	.finePrint {
		font-size: 6px;
	}
	.btcpay-form { display: inline-flex; align-items: center; justify-content: center; } 
	.btcpay-form--inline { flex-direction: row; } 
	.btcpay-form--block { flex-direction: column; } 
	.btcpay-form--inline .submit { margin-left: 15px; } 
	.btcpay-form--block select { margin-bottom: 10px; } 
	.btcpay-form .btcpay-custom-container{ text-align: center; }
	.btcpay-custom { display: flex; align-items: center; justify-content: center; } 
	.btcpay-form .plus-minus { cursor:pointer; font-size:25px; line-height: 25px; background: #DFE0E1; height: 30px; width: 45px; border:none; border-radius: 60px; margin: auto 5px; display: inline-flex; justify-content: center; } 
	.btcpay-form select { -webkit-appearance: none; appearance: none; color: currentColor; background: transparent; border:1px solid transparent; display: block; padding: 1px; margin-left: auto; margin-right: auto; font-size: 11px; cursor: pointer; } 
	.btcpay-form select:hover { border-color: #ccc; } 
	#btcpay-input-price { -moz-appearance: none; -webkit-appearance: none; border: none; box-shadow: none; text-align: center; font-size: 25px; margin: auto; border-radius: 5px; line-height: 35px; background: #fff; } 
	#btcpay-input-price::-webkit-outer-spin-button, #btcpay-input-price::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }

}

@media all and (min-width: 480px) {
	.FlightSettings {
		padding: 60px 0;
		margin: 0 auto;
		max-width: 480px;
	}
}

.NotFound {
	padding-top: 100px;
	text-align: center;
}

.Blog {
	padding: 30px 0;
	max-width: 480px;
}
.pageHeader {
	text-align: center;
}

.NewNote form {
	padding-bottom: 15px;
}

.NewNote form textarea {
	height: 300px;
	font-size: 24px;
}

.App {
	margin-top: 15px;
}

.App .navbar-brand {
	font-weight: bold;
}
.linkDiv {
	margin-top: 5px;
	margin-bottom: 5px;
}
body {
	margin: 0;
	padding: 0;
	font-family: "Open Sans", sans-serif;
	font-size: 16px;
	color: #333;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "PT Serif", serif;
}
select.form-control,
textarea.form-control,
input.form-control {
	font-size: 16px;
}
input[type="file"] {
	width: 100%;
}


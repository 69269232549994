.App {
	margin-top: 15px;
}

.App .navbar-brand {
	font-weight: bold;
}
.linkDiv {
	margin-top: 5px;
	margin-bottom: 5px;
}
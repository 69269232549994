@media all and (min-width: 480px) {
	.Signup {
		padding: 60px 0;
	}

	.Signup form {
		margin: 0 auto;
		max-width: 320px;
	}
}

.Signup form div div label a {
	color: blue;
	font-weight: bold;
}

.sectionSubtitle {
	font-style: italic;
	font-size: 12px;
}

.Signup .pageButtonGroup div {
	margin: 0 auto;
	max-width: 320px;
	text-align: center;
}
/* #root > div > div > div > div > button */
.Signup .pageButtonGroup div button:first-child {
	margin-right: 20px;
}

.Signup form span.help-block {
	font-size: 14px;
	padding-bottom: 10px;
	color: #999;
}

FormGroup.checkbox {
	display: inline-block;
	display: flex;
	flex-direction: row-reverse;
}

FormGroup.checkbox > label {
	display: flex;
	flex-direction: row-reverse;
}

@media all and (min-width: 480px) {
	.ResetPassword {
		padding: 60px 0;
	}

	.ResetPassword form {
		margin: 0 auto;
		max-width: 320px;
	}

	.ResetPassword .success {
		max-width: 400px;
	}
}

.ResetPassword .success {
	margin: 0 auto;
	text-align: center;
}
.ResetPassword .success .glyphicon {
	color: grey;
	font-size: 30px;
	margin-bottom: 30px;
}

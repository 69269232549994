@media all and (min-width: 480px) {
	.Login {
		padding: 0px 0;
		max-width: 480px;
		margin: 0 auto;
	}

	.Login form {
		margin: 0 auto;
	}

	.Login form a {
		margin-bottom: 15px;
		display: block;
		font-size: 14px;
		color: blue;
	}
}

@media all and (min-width: 480px) {
	.Deals {
		padding: 60px 0;
		max-width: 480px;
		margin: 0 auto;
	}

	.Deals form {
		margin: 0 auto;
		max-width: 480px;
	}
	.flight-card-link {
		text-align: left;
	}
}

.page-header,
.page-description {
	text-align: center;
}

.Deals .lander {
	padding: 0px 0;
	text-align: center;
}

.list-group {
	padding-bottom: 100px;
}

.Deals .lander div {
	margin: 0 auto;
	max-width: 480px;
}

.Deals .lander h1 {
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
}

.sectionSubtitle {
	font-style: italic;
	font-size: 12px;
	text-align: center;
}

.slideContainer {
	margin: auto;
	max-width: 480px;
	background: #eeeeee;
}

.Deals .lander p {
	color: #999;
}

.Deals .lander div {
	padding-top: 0px;
}
.Deals .lander div a:first-child {
	margin-right: 20px;
}

.slick-dots {
	top: -5px;
}

.slideHeader {
	text-align: center;
}

.special-text:not(.expanded)::after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.special-text {
	position: relative;
	max-height: 80px;
	overflow: hidden;
	transition: max-height 0.4s ease;
}

.special-text.expanded {
	max-height: 1000px;
	transition: max-height 0.4s ease-in-out;
}

.pageDescriptionExpandable-text:not(.expanded)::after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.pageDescriptionExpandable-text {
	position: relative;
	max-height: 0px;
	overflow: hidden;
	transition: max-height 0.4s ease;
}

.pageDescriptionExpandable-text.expanded {
	max-height: 1000px;
	transition: max-height 0.4s ease-in-out;
}

.expandPageDescription {
	font-size: 15px;
	text-align: center;
}

.carouselContentDiv {
	padding-top: 30px;
}

.slideCountDiv {
	padding-top: 10px;
}

.slideContainer {
	top: 40px;
}

.slick-arrow {
	top: 0%;
	color: #000000;
	background: #bbbbbb;
	width: 40px;
	height: 40px;
	top: -20px;
	position: top;
}

.slick-next {
	right: 0px;
}

.slick-prev {
	left: 0px;
}

details {
	margin-bottom: 20px;
}

.Home .lander {
	padding: 0px 0;
	text-align: center;
}
.linkBtn {
	margin-top: 5px;
	margin-bottom: 5px;
}

.Home .lander h1 {
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
}

.Home .lander p {
	color: #999;
}

.Home .lander div {
	padding-top: 0px;
}
.Home .lander div a:first-child {
	margin-right: 20px;
}

.Home .notes h4 {
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
	overflow: hidden;
	line-height: 1.5;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.Home .notes p {
	color: #666;
}
a {
	color: black;
	text-decoration: none; /* no underline */
}
a:hover {
	color: black;
	text-decoration: none; /* no underline */
}
